import React from 'react';
import {embeddedAuthProvider, UserData} from '@openstax/ts-utils/services/authProvider/utils/embeddedAuthProvider';

export const useEmbeddedAuthProvider = (userData: UserData) => {
  const embeddedAuthProviderRef = React.useRef<ReturnType<typeof embeddedAuthProvider>>();

  embeddedAuthProviderRef.current = embeddedAuthProviderRef.current || embeddedAuthProvider(
    () => Promise.resolve(userData), { authQuery: { key: 'auth', value: userData.token }, window }
  );

  React.useEffect(() => {
    return () => {
      if (embeddedAuthProviderRef.current) {
        embeddedAuthProviderRef.current.unmount();
        embeddedAuthProviderRef.current = undefined;
      }
    };
  }, [userData]);

  return embeddedAuthProviderRef.current;
};
