 import React from 'react';
import { createRoute, makeScreen } from "../../core/services";
import { createGlobalStyle } from 'styled-components';
import { useApiClient } from "../../api";
import {
  stateHasError,
  stateHasData,
  FetchState,
  fetchLoading,
  fetchSuccess,
  fetchIdle,
  FetchStateType,
} from "@openstax/ts-utils/fetch";
import {selectContentPath} from '@project/lambdas/build/src/services/LtiJs/paths.config';
import {isDeepLinkContentSelectedMessage} from '@openstax/lti/lti-gateway/post-messages';
import { useLtiLinkedUserData } from "../../ltiLinkedAccount/context";
import { useEmbeddedAuthProvider } from "../../hooks/useEmbeddedAuthProvider";
import { LtiLinkedAccountProvider, AccessRole } from "../../ltiLinkedAccount/components/LtiLinkedAccountProvider";
import * as UI from '@openstax/ui-components';
import { LtiResource, LtiResourceLink } from '@openstax/lti';

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const useSelectTrigger = (selectContentUrl: string | undefined) => {
  const apiClient = useApiClient();
  const [state, setState] = React.useState<FetchState<string, string>>(fetchIdle());
  const setAppError = UI.useSetAppError();

  const handleCallback = React.useCallback((e: MessageEvent<any>) => {
    if (selectContentUrl && e.origin === new URL(selectContentUrl).origin && isDeepLinkContentSelectedMessage(e.data)) {
      setState(previous => fetchLoading(previous));

      apiClient.apiV0DeepLinkCreate({
        payload: {
          ...e.data.payload,
          items: e.data.payload.items.map((activity: LtiResource | LtiResourceLink) =>
            'item' in activity ? activity : {item: activity})
        },
      })
        .then(response => response.acceptStatus(200).load())
        .then(response => setState(fetchSuccess(response)))
        .catch(setAppError)
      ;
    }
  }, [apiClient, selectContentUrl, setAppError]);

  React.useEffect(() => {
    if (state.type === FetchStateType.SUCCESS) {
      const template = document.createElement('div');
      template.innerHTML = state.data;

      // there is a self submit script in here too, but it doesn't
      // work when dynamically adding to the body
      const form = template.getElementsByTagName("form")[0];
      document.body.append(form);
      form.submit();
    }
  }, [state]);

  React.useEffect(() => {
    window.addEventListener('message', handleCallback, false);
    return () => window.removeEventListener('message', handleCallback);
  }, [handleCallback]);
};

export const useSelectContentUrl = () => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const [state, setState] = React.useState<FetchState<{url: string}, string>>(fetchLoading());

  React.useEffect(() => {
    apiClient.apiV0SelectContentUrl({})
      .then(response => response.acceptStatus(200).load())
      .then(response => setState(fetchSuccess(response)))
      .catch(setAppError)
    ;
  }, [apiClient, setAppError]);

  return state;
};

const Embed = () => {
  const selectContentUrl = useSelectContentUrl();
  useSelectTrigger(stateHasData(selectContentUrl) ? selectContentUrl.data.url : undefined);
  const userData = useLtiLinkedUserData() as any; // TODO: Remove "as any"
  const authProvider = useEmbeddedAuthProvider(userData);

  React.useEffect(() => {
    document.body.classList.add('os-embed');
    return () => document.body.classList.remove('os-embed');
  }, []);

  return userData.user?.consent_preferences ? <>
    {stateHasError(selectContentUrl)
      ? <UI.Error />
      : stateHasData(selectContentUrl) ? <iframe
          title="select content"
          style={{width: '100%', height: '100%', border: 'none'}}
          src={authProvider.getAuthorizedEmbedUrl(selectContentUrl.data.url)}
        ></iframe> : <UI.Loader />
    }
  </> : null;
};

export const Select = () => {
  return <LtiLinkedAccountProvider role={AccessRole.INSTRUCTOR}>
    <GlobalStyle />
    <Embed />
  </LtiLinkedAccountProvider>;
};

export const selectScreen = createRoute({name: 'Select', path: selectContentPath,
  handler: makeScreen(Select)
});
