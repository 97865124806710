import {ReactNode} from 'react';
import { makeRenderRouteUrl } from '@openstax/ts-utils/routing';
import { getKeyValue } from '@openstax/ts-utils';
import { makeGetRequestResponder } from '@openstax/ts-utils/routing';
import { AppServices } from './types';
import { Location } from "history";
import { selectScreens } from "../selectContent/screens";
import { launchScreens } from "../launchContent/screens";
import { registrationScreens } from '../ltiRegistration/screens';
import { linkAccountScreens } from '../ltiLinkedAccount/screens';
import { genericScreens } from '../generic/screens';

export const routes = () => ([
  ...selectScreens(),
  ...launchScreens(),
  ...registrationScreens(),
  ...linkAccountScreens(),
  ...genericScreens(),
]);

export type TRoutes = (ReturnType<typeof routes>)[number];

export const renderRouteUrl = makeRenderRouteUrl<TRoutes>();

export const getRequestResponder = makeGetRequestResponder<AppServices, TRoutes, Location, ReactNode>()({
  routes,
  pathExtractor: getKeyValue('pathname'),
});
