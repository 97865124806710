import React, { InputHTMLAttributes, ReactNode } from 'react';
import { FieldActions } from './reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-regular-svg-icons/faClone';
import styled, { css } from 'styled-components';
import { Checkbox, colors, useSetAppError } from '@openstax/ui-components';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import { GroupBase } from 'react-select';

export const FieldWrapper = styled(({flexRow, indented, invalid, ...props }) =>
  <div {...props}>{props.children}</div>
)`
  ${({ indented }: { indented?: boolean }) => indented && css`
    margin-left: 1.6rem;
  `}

  .input-wrapper {
    display: flex;
    margin: 1.8rem 0 0;

    & > input, & > div {
      flex: 1;
    }
  }
  label {
    display: flex;
    flex-direction: column;
    margin-top: 1.6rem;
    position: relative;
    font-size: 1.6rem;
    ${({ flexRow }: { flexRow?: boolean }) => flexRow && css`
      flex-direction: row;
    `}
  }
  .input-wrapper > input {
    font-size: 1.6rem;
    padding: 1.6rem;
    border: 1px solid ${colors.palette.darkGray};
    border-radius: 2px;
    &:focus {
      border-color: ${colors.palette.neutralLightBlue};
      outline: ${colors.palette.neutralLightBlue};
      box-shadow: 0px 0px 4px rgba(13, 192, 220, 0.5);
    }
    ${({ invalid }: { invalid: boolean }) => invalid && css`
      border-color: ${colors.palette.lightRed};
      background: ${colors.palette.paleRed};
      color: ${colors.palette.darkRed};
    `}
  }
  input[type="radio"] {
    accent-color: ${colors.palette.mediumBlue};
    width: 1.6rem;
    height: 1.6rem;
    margin: 0 1.6rem 0 0;
  }
  button {
    width: 4.2rem;
    margin-left: -4.2rem;
    background: none;
    border: 0;
    cursor: pointer;

    &:hover {
      color: ${colors.palette.neutralDark};
    }
  }
  .tooltip:before {
    content: 'Copied to clipboard';
    background: #222;
    color: #fff;
    display: none;
    position: absolute;
    padding: 1rem;
    right: 0;
    margin-top: 4.1rem;
    border-radius: .2rem;
    pointer-events: none;
  }
  .tooltip.show:before {
    display: block;
  }
`;

export const CopyField = ({ label, helperText, value }: {
  label: ReactNode;
  helperText?: string;
  value: string;
}) => {
  const [copied, setCopied] = React.useState(false);
  const setAppError = useSetAppError();
  const selectText = (e: React.FocusEvent<HTMLInputElement>) => e.target.select();
  const copyText = async() => {
    await navigator.clipboard.writeText(value).then(() => {
      setCopied(true);
    }).catch(setAppError);
  };

  React.useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [copied]);

  return (
    <FieldWrapper>
      <label>
        {label}
        {helperText ? <HelperText>{helperText}</HelperText> : null}
        <div className='input-wrapper'>
          <input readOnly value={value} onFocus={selectText} />
          <button aria-label='Copy to clipboard' onClick={copyText}>
            <span className={`tooltip ${copied ? 'show' : ''}`}>
              <FontAwesomeIcon icon={faClone} aria-hidden />
            </span>
          </button>
        </div>
      </label>
    </FieldWrapper>
  );
};

export const InputField = ({
  invalid, invalidText, label, onChange, ...props
}: Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  invalid?: boolean;
  invalidText?: string | React.ReactNode;
  label: ReactNode;
  onChange?: React.Dispatch<FieldActions['payload']>;
}) => {
  return (
    <FieldWrapper invalid={invalid}>
      <label>
        {label}
        <div className='input-wrapper'>
          <input
            {...props}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => onChange && onChange(e.target.value)}
          />
        </div>
      </label>
      {invalid && invalidText ? <InvalidText>{invalidText}</InvalidText> : null}
    </FieldWrapper>
  );
};

const InvalidText = styled.div`
  color: ${colors.palette.red};
  font-size: 1.4rem;
  line-height: 2.4rem;
`;

const HelperText = styled.div`
  color: ${colors.palette.neutralThin};
  font-size: 1.4rem;
  line-height: 2.4rem;
  & + .input-wrapper {
    margin-top: 1.2rem;
  }
`;

export const CheckboxField = (props: React.ComponentProps<typeof Checkbox> & {
  indented?: boolean;
}) => <FieldWrapper flexRow indented={props.indented || false}>
  <Checkbox {...props} />
</FieldWrapper>;

type RadioFieldProps = {
  value: string | null | undefined;
  onChangeValue: (value: string) => void;
  options: Array<{
    label: string;
    value: string;
  }>;
};
export const RadioField = ({
  options,
  value,
  onChangeValue,
  ...props
}: Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> & RadioFieldProps) => <FieldWrapper flexRow>
  {options.map(option => <label key={option.value}>
    <input
      {...props}
      type="radio"
      value={option.value}
      checked={option.value === value}
      onChange={e => {
        props.onChange?.(e);
        onChangeValue(e.target.value);
      }}
    />
    {option.label}
  </label>)}
</FieldWrapper>;

export const AsyncSelectField = <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>({
  label,
  ...props
}: AsyncProps<OptionType, IsMulti, GroupType> & {
  label: string;
  searchErrorMessage?: string;
  noResultsMessage: string;
}) => {
  const noOptionsMessage = ({ inputValue }: { inputValue: string }) => {
    if (inputValue.length < 3) {
      return 'Please enter at least 3 characters.';
    }
    if (props.searchErrorMessage) {
      return props.searchErrorMessage;
    } else {
      return props.noResultsMessage;
    }
  };

  const DropdownIndicator = () => null;
  const IndicatorSeparator = () => null;

  return <FieldWrapper>
    <label>
      {label}
      <div className='input-wrapper'>
        <AsyncSelect
          {...props}
          noOptionsMessage={noOptionsMessage}
          components={{ DropdownIndicator, IndicatorSeparator }}
          isClearable
          isSearchable
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? colors.palette.neutralLightBlue : colors.palette.darkGray,
              ":hover": {
                borderColor: state.isFocused ? colors.palette.neutralLightBlue : colors.palette.darkGray,
                cursor: 'text',
              },
              boxShadow: state.isFocused ? '0px 0px 4px rgba(13, 192, 220, 0.5)' : baseStyles.boxShadow,
              borderRadius: '2px',
              outline: state.isFocused ? colors.palette.neutralLightBlue : baseStyles.outline,
              padding: '0.6rem',
              height: '5.2rem',
            })
          }}
        />
      </div>
    </label>
  </FieldWrapper>;
};
