import React from 'react';
import { FetchStateType } from "@openstax/ts-utils/fetch";
import * as UI from '@openstax/ui-components';
import { StateContext } from ".";
import { SearchOrganizations } from "./SearchOrganizations";
import { validateEmail } from "./helpers";
import { InputField } from "./fields";
import styled from 'styled-components';

const StyledFormTag = styled.form`
  flex: 1;
`;

export const FormTag = (
  props: { children: React.ReactNode; className?: string; onSubmit: () => void }
) => <StyledFormTag className={props.className} action="" onSubmit={(e) => {
  e.preventDefault();
  props.onSubmit();
}}>{props.children}</StyledFormTag>;

export const AdminInfoFields = () => {
  const { state: {contactPayload: {adminName, adminEmail}}, dispatch } = React.useContext(StateContext);
  const [showEmailInvalid, setShowEmailInvalid] = React.useState<boolean>(false);

  const emailIsValid = React.useMemo(() => adminEmail ? validateEmail(adminEmail) : null
  , [adminEmail]);

  return <>
    <h3>Fill in the fields below.</h3>
    <InputField
      label='LTI Admin Name'
      value={adminName}
      onChange={(name) => dispatch({ type: 'setContactField', field: 'adminName', payload: name })}
    />
    <InputField
      label='LTI Admin Email'
      value={adminEmail}
      onChange={(email) => dispatch({ type: 'setContactField', field: 'adminEmail', payload: email })}
      onBlur={() => { setShowEmailInvalid(!emailIsValid); }}
      onFocus={() => { setShowEmailInvalid(false); }}
      invalid={!!adminEmail && showEmailInvalid}
      invalidText='Email is invalid'
    />
    <SearchOrganizations />
  </>;
};

export const useAdminInfoIsValid = () => {
  const { adminName, adminEmail, organizationId } = React.useContext(StateContext).state.contactPayload;

  const isValid = React.useMemo(() =>
    !!adminName && !!adminEmail && !!validateEmail(adminEmail) && !!organizationId
  , [adminName, adminEmail, organizationId]);

  return isValid;
};

export const SubmitAdminInfoButton = () => {
  const { state } = React.useContext(StateContext);
  const isValid = useAdminInfoIsValid();

  return <UI.Button
    type='submit'
    disabled={!isValid}
    isWaiting={state.apiState.type === FetchStateType.LOADING}
    waitingText='Saving...'
  >
    Submit
  </UI.Button>;
};
