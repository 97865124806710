import { isAccountLinkedMessage } from "@openstax/lti";
import { assertString } from "@openstax/ts-utils/assertions";
import {
  stateHasData
} from "@openstax/ts-utils/fetch";
import * as UI from '@openstax/ui-components';
import React, { Dispatch } from 'react';
import { useFrontendConfigValue } from '../../../configProvider/use';
import { useQuery } from '../../../routing/useQuery';
import * as Styled from '../../../generic/screens/styled';
import { renderRouteUrl } from "../../../core";
import { confirmLinkAccountScreen } from "../../screens/link";

declare global {
  interface Window {
    sendConsentToAccounts: () => void;
  }
}

export const LoginSignup = ({ setLinkComplete }: { setLinkComplete: Dispatch<boolean> }) => {
  const popupHandle = React.useRef<Window | null>();
  const accountsBase = useFrontendConfigValue('accountsBase');
  const query = useQuery();
  const { protocol, host } = window.location;
  const hostWithProtocol = `${protocol}//${host}`;

  const handlePopup = () => {
    if (!stateHasData(accountsBase)) {
      throw new Error('Expected accountsBase to load from config');
    }

    const ltik = assertString(query.ltik, new Error('Expected ltik to be a string'));

    popupHandle.current = window.open(
      renderRouteUrl(confirmLinkAccountScreen, undefined, { ltik }),
      'linkAccountWindow',
      'popup,width=508,height=820'
    );
  };

  React.useEffect(() => {
    if (!stateHasData(accountsBase)) {
      return;
    }

    const handleMessage = (e: MessageEvent<any>) => {
      if (e.origin !== hostWithProtocol) {
        return;
      }
      if (isAccountLinkedMessage(e.data)) {
        popupHandle.current?.close();
        window.sendConsentToAccounts();
        setLinkComplete(true);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [accountsBase, setLinkComplete, hostWithProtocol]);

  return stateHasData(accountsBase) ? <>
    <UI.NavBar logo />
    <Styled.Screen>
      <h1>OpenStax Assignable</h1>
      <p>Assignable is a new, free way to enhance the OpenStax experience for you and your students.
         Assignable empowers instructors to easily assign readings, assessments, and culturally
         responsive and interactive materials from the OpenStax Resource Library.</p>
      <div className="buttons bold">
        <UI.Button onClick={handlePopup}>
          Link your OpenStax account
        </UI.Button>
      </div>

      <p>Log in with your OpenStax credentials or sign up for an account.</p>
    </Styled.Screen>
  </> : <UI.Loader />;
};
