import {
  fetchLoading, FetchState, fetchSuccess,
  stateHasData,
  stateHasError
} from "@openstax/ts-utils/fetch";
import {
  SessionProblems, AuthResponse, SessionProblemTypes, AccessRole
} from '@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/auth.integration';
import * as UI from '@openstax/ui-components';
import React from 'react';
import { useApiClient } from "../../../api";
import { useFrontendConfigValue } from "../../../configProvider/use";
import { LtiLinkedAccountContext } from "../../context";
import { LoginSignup } from "./LoginSignup";
import { WaitForVerification } from "./WaitForVerification";
import { Redirect } from "../../../components/Redirect";

export { AccessRole };

const useLtiLinkedAccountData = (linkComplete: boolean, role?: AccessRole) => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const [state, setState] = React.useState<FetchState<SessionProblems | AuthResponse, string>>(fetchLoading());

  React.useEffect(() => {
    apiClient.apiV0GetLtiLinkedAccount({query: {role: role?.toString()}})
      .then((response): Promise<SessionProblems | AuthResponse> =>
        response.acceptStatus(200, 403).load()
      )
      .then(response => setState(fetchSuccess(response)))
      .catch(setAppError);
  }, [apiClient, linkComplete, role, setAppError]);

  return state;
};

export const LtiLinkedAccountProvider = ({children, role}: React.PropsWithChildren<{role?: AccessRole}>) => {
  const [linkComplete, setLinkComplete] = React.useState(false);
  const userDataState = useLtiLinkedAccountData(linkComplete, role);
  const accountsBase = useFrontendConfigValue('accountsBase');

  if (stateHasError(accountsBase)) {
    return <UI.Error />;
  }
  if (!stateHasData(userDataState) || !stateHasData(accountsBase)) {
    return <UI.Loader />;
  }
  const {data} = userDataState;

  if ('error' in data) {
    switch (data.error) {
      case SessionProblemTypes.UNLINKED_INSTRUCTOR_ACCOUNT: {
        return <LoginSignup setLinkComplete={setLinkComplete} />;
      }
      case SessionProblemTypes.UNVERIFIED_INSTRUCTOR_ACCOUNT: {
        return <WaitForVerification />;
      }
      case SessionProblemTypes.SIGNATURE_REQUIRED: {
        const {contract, token} = data;
        const route = `/terms/${contract}/pose`;
        const redirect = encodeURIComponent(window.location.href);

        return <Redirect url={`${accountsBase.data}${route}?token=${token}&r=${redirect}`} />;
      }
      default: {
        throw new Error(`unrecognized session state ${JSON.stringify(userDataState.data)}`);
      }
    }
  }

  return <LtiLinkedAccountContext.Provider value={() => data}>
    {children}
  </LtiLinkedAccountContext.Provider>;
};
