import React from 'react';
import { UserData } from '@openstax/ts-utils/services/authProvider/utils/embeddedAuthProvider';
import { AccessRole } from './components/LtiLinkedAccountProvider';

export const LtiLinkedAccountContext = React.createContext<() => UserData & { role: AccessRole }>(() => {
  throw new Error('context not provided');
});

export const useLtiLinkedUserData = () => {
  return React.useContext(LtiLinkedAccountContext)();
};
