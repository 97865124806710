import * as UI from '@openstax/ui-components';
import * as Styled from '../../../generic/screens/styled';
import styled from 'styled-components';

const StyledH2 = styled.h2`
  margin-bottom: 0;
`;

export const WaitForVerification = () => <>
  <UI.NavBar logo />
  <Styled.Screen centered>
    <StyledH2>Waiting for instructor verification</StyledH2>
    <p>Please check back in a few moments.</p>
    <div className="buttons">
      <UI.LinkButton
        href='https://openstax.org/subjects/view-all'
        target='_blank'
      >
        Explore textbooks
      </UI.LinkButton>
      <UI.LinkButton
        href='https://help.openstax.org/s/contactsupport'
        target='_blank'
        variant='light'
      >
        Contact customer service
      </UI.LinkButton>
    </div>
  </Styled.Screen>
</>;
