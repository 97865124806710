import { makeAccountLinkedMessage } from '@openstax/lti';
import { assertString } from '@openstax/ts-utils/assertions';
import {
  fetchLoading, FetchState, fetchSuccess, stateHasData, stateHasError
} from "@openstax/ts-utils/fetch";
import { ApiUser } from '@openstax/ts-utils/services/authProvider';
import * as UI from '@openstax/ui-components';
import React from 'react';
import styled from 'styled-components';
import { useApiClient } from "../../api";
import { useFrontendConfigValue } from '../../configProvider/use';
import { renderRouteUrl } from '../../core';
import { createRoute, makeScreen } from "../../core/services";
import { InputField } from '../../ltiRegistration/screens/register/fields';
import { useQuery } from '../../routing/useQuery';
import * as Styled from '../../generic/screens/styled';

type ApiUserWithUsername = ApiUser & {
  username?: string;
};

const useLtik = () => assertString(useQuery().ltik, new Error('Expected ltik to be a string'));

export const makeAccountsUrlWithRedirect = (
  accountsBase: string,
  route: 'login' | 'signout',
  ltik: string,
  redirect?: string,
) => {
  const { protocol, host } = window.location;
  const hostWithProtocol = `${protocol}//${host}`;
  const defaultRedirect = hostWithProtocol + renderRouteUrl(linkAccountScreen, undefined, { ltik });
  return `${accountsBase}/i/${route}?r=${encodeURIComponent(redirect || defaultRedirect)}`;
};

export const useLinkUrl = () => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const [state, setState] = React.useState<FetchState<{}, string>>(fetchLoading());

  React.useEffect(() => {
    apiClient.apiV0LinkAccount({})
      .then(response => response.acceptStatus(200, 403))
      .then(response => setState(fetchSuccess(response)))
      .catch(setAppError)
    ;
  }, [apiClient, setAppError]);

  return state;
};

const useAccountsUserUrl = () => {
  const accountsBase = useFrontendConfigValue('accountsBase');
  const setAppError = UI.useSetAppError;
  const [state, setState] = React.useState<FetchState<ApiUserWithUsername, string>>(fetchLoading());
  const ltik = useLtik();

  React.useEffect(() => {
    if (!stateHasData(accountsBase)) {
      return;
    }

    fetch(`${accountsBase.data}/api/user`, { credentials: 'include' })
      .then(async(response) => {
        if (response.status === 200) {
          return setState(fetchSuccess(await response.json() as ApiUser));
        } else if (response.status === 403) {
          // If we fetch when the user is not signed in, Accounts will return a 403.
          // This won't happen normally but we can handle it by forcing a login.
          window.location.assign(
            makeAccountsUrlWithRedirect(
              accountsBase.data,
              'login',
              ltik
            )
          );
          return;
        }
        const message = await response.text();
        throw new Error(`Error response from Accounts ${response.status}: ${message}`);
      })
      .catch(setAppError);
  }, [accountsBase, ltik, setAppError]);

  return state;
};

const findUserPreferredEmail = (user: ApiUser) =>
  (user.contact_infos &&
    user.contact_infos.length > 0 &&
    user.contact_infos.find(i => i.type === 'EmailAddress' && i.is_guessed_preferred)?.value)
  || undefined;

const DoLink = () => {
  const state = useLinkUrl();
  const config = useFrontendConfigValue('uiHost');

  if (stateHasError(config)) {
    return <UI.Error />;
  } else if (!stateHasData(config) || !stateHasData(state)) {
    return <UI.Loader />;
  }

  window.opener.postMessage(makeAccountLinkedMessage(), config.data);

  return null;
};

const Logo = styled.div`
  padding: 2rem 2rem 1.4rem;

  svg {
    height: 2.8rem;
  }
`;

const StyledScreen = styled(Styled.Screen)`
  padding: 1rem;
`;

const Wrapper = styled.div`
  color: ${UI.colors.palette.neutralDarker};

  .tabs {
    display: flex;
    border: 1px solid ${UI.colors.palette.pale};
    border-bottom: 0;
    border-radius: 0.3rem;

    > * {
      flex: 1;
      padding: 1.2rem 0;
      font-size: 1.8rem;
      font-weight: bold;
      letter-spacing: -0.072rem;
      display: flex;
      align-items: center;
      justify-content: center;

      & + * {
        background: ${UI.colors.palette.neutralBright};
        color: inherit;
        text-decoration: none;
        border: 1px solid ${UI.colors.palette.pale};
        border-width: 0px 0px 1px 1px;
      }
    }
  }

  .body {
    min-height: 68vh;
    border: 1px solid ${UI.colors.palette.pale};
    border-top: 0;
    padding: 6rem 1.4rem;

    h3 {
      font-size: 1.8rem;
      text-align: center;
      margin: 0 0 1rem;
    }

    .field {
      display: flex;
      margin-bottom: 3.2rem;

      > *:first-child {
        flex: 1;
      }

      .signout {
        align-self: end;
        width: 10rem;
        font-size: 1.4rem;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        color: ${UI.colors.palette.mediumBlue};
        padding: 0 1rem 1.6rem 1.4rem;
      }
    }

    label {
      font-weight: bold;
      font-size: 1.4rem;

      > * {
        margin: 0.5rem 0 0;
      }
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2.1rem;
  padding-bottom: 1rem;
  color: #000;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const StyledRiceLogo = styled(UI.RiceLogo)`
  width: 10rem;
  margin-bottom: 2.6rem;
`;

const ConfirmLink = ({ setLinkConfirmed }: { setLinkConfirmed: React.Dispatch<boolean> }) => {
  const userDataState = useAccountsUserUrl();
  const accountsBase = useFrontendConfigValue('accountsBase');
  const ltik = useLtik();

  if (!stateHasData(accountsBase) || !stateHasData(userDataState)) {
    return <UI.Loader />;
  }

  const currentYear = new Date().getFullYear();
  const signoutUrl = makeAccountsUrlWithRedirect(
    accountsBase.data,
    'signout',
    ltik,
    makeAccountsUrlWithRedirect(
      accountsBase.data,
      'login',
      ltik
    )
  );
  const email = findUserPreferredEmail(userDataState.data);
  const { username, name } = userDataState.data;

  let label, value;
  const dataPair = [['Email', email], ['Username', username], ['Name', name]].find(el => el[1]);

  if (dataPair) {
    [label, value] = dataPair;
  }

  // It's rare but possible for an account to only have a name or username, some
  // parts of the UI should be conditional to make those edge cases look right.
  return <>
    <Logo><UI.NavBarLogo alt='OpenStax logo' /></Logo>
    <StyledScreen>
      <Wrapper>
        <div className='tabs'>
          <div role='tab' aria-selected='true' tabIndex={-1}>Log in</div>
          <div></div>
        </div>
        <div className='body'>
          <h3>Confirm your OpenStax {email ? 'email' : 'account'} to continue</h3>
          <div className='field'>
            {value ? <>
              <InputField label={label} disabled value={value} />
              <a href={signoutUrl} className='signout'>
                Not you?
              </a>
            </> : null}
          </div>
          <UI.Button onClick={() => setLinkConfirmed(true)}>
            Link {value ? 'this' : null} account
          </UI.Button>
          {!value ? <UI.LinkButton href={signoutUrl} variant='light'>
            Sign out
          </UI.LinkButton> : null}
        </div>
      </Wrapper>
    </StyledScreen>
    <Footer>
      <StyledRiceLogo />
      <div>
        Copyright © 2013-{currentYear}&nbsp;
        <a href={`${accountsBase.data}/copyright`} target='_blank' rel='noreferrer'>
          Rice University
        </a>&nbsp;|&nbsp;
        <a href={`${accountsBase.data}/terms`} target='_blank' rel='noreferrer'>Terms of Use</a>
      </div>
    </Footer>
  </>;
};

export const LinkWithConfirmation = () => {
  const [linkConfirmed, setLinkConfirmed] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add('os-popup');
    return () => document.body.classList.remove('os-popup');
  }, []);

  return linkConfirmed ? <DoLink /> : <ConfirmLink setLinkConfirmed={setLinkConfirmed} />;
};

export const confirmLinkAccountScreen = createRoute({name: 'Confirm Link Account', path: '/confirm-account',
  handler: makeScreen(LinkWithConfirmation)
});

export const linkAccountScreen = createRoute({name: 'Link Account', path: '/link-account',
  handler: makeScreen(DoLink)
});
