import * as UI from '@openstax/ui-components';
import styled, { css } from "styled-components";

export const Screen = styled.div`
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6rem 4.2rem;

  ${(props: { centered?: boolean }) => props.centered && css`
    align-items: center;
  `}

  h1 {
    font-family: 'Libre Baskerville', serif;
    font-size: 3.6rem;
    line-height: 4.8rem;
    letter-spacing: -1px;
  }

  h2 {
    font-size: 2.4rem;
    line-height: 3rem;
    letter-spacing: -4%;
  }

  h1, h2 {
    color: ${UI.colors.palette.neutralDarker};
  }

  p {
    color: ${UI.colors.palette.neutralThin};
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .buttons {
    margin: 2.4rem 0 1.6rem;
    & button {
      margin: 1rem 1rem 0 0;
      min-width: 14.4rem;
    }
    &.bold button {
      font-weight: bold;
    }
  }
`;
