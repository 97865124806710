"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLtiResource = exports.validateLtiResource = exports.isLtiResourceLink = exports.validateLtiResourceLink = void 0;
const assertions_1 = require("@openstax/ts-utils/assertions");
const errors_1 = require("@openstax/ts-utils/errors");
const guards_1 = require("@openstax/ts-utils/guards");
const validateLtiImage = (input) => (0, guards_1.isPlainObject)(input)
    && Object.keys(input).every((key) => [
        'url', 'width', 'height',
    ].includes(key))
    && (0, assertions_1.assertTrue)(['string'].includes(typeof input.url), new errors_1.InvalidRequestError('url must be a string'))
    && (0, assertions_1.assertTrue)(['number', 'undefined'].includes(typeof input.width), new errors_1.InvalidRequestError('width must be a number'))
    && (0, assertions_1.assertTrue)(['number', 'undefined'].includes(typeof input.height), new errors_1.InvalidRequestError('height must be a number'));
const validateLtiLineItem = (input) => (0, guards_1.isPlainObject)(input)
    && Object.keys(input).every((key) => [
        'label', 'scoreMaximum', 'resourceId', 'tag',
    ].includes(key))
    && (0, assertions_1.assertTrue)(['number'].includes(typeof input.scoreMaximum), new errors_1.InvalidRequestError('scoreMaximum must be a number'))
    && (0, assertions_1.assertTrue)(['string', 'undefined'].includes(typeof input.label), new errors_1.InvalidRequestError('label must be a string'))
    && (0, assertions_1.assertTrue)(['string', 'undefined'].includes(typeof input.resourceId), new errors_1.InvalidRequestError('resourceId must be a string'))
    && (0, assertions_1.assertTrue)(['string', 'undefined'].includes(typeof input.tag), new errors_1.InvalidRequestError('tag must be a string'));
const validateLtiLinkWindow = (input) => (0, guards_1.isPlainObject)(input)
    && Object.keys(input).every((key) => [
        'targetName', 'width', 'height', 'windowFeatures'
    ].includes(key))
    && (0, assertions_1.assertTrue)(['string', 'undefined'].includes(typeof input.targetName), new errors_1.InvalidRequestError('targetName must be a string'))
    && (0, assertions_1.assertTrue)(['string', 'undefined'].includes(typeof input.windowFeatures), new errors_1.InvalidRequestError('windowFeatures must be a string'))
    && (0, assertions_1.assertTrue)(['number', 'undefined'].includes(typeof input.width), new errors_1.InvalidRequestError('width must be a number'))
    && (0, assertions_1.assertTrue)(['number', 'undefined'].includes(typeof input.height), new errors_1.InvalidRequestError('height must be a number'));
const validateLtiResourceLink = (input) => (0, guards_1.isPlainObject)(input)
    && Object.keys(input).every((key) => [
        'url', 'title', 'text', 'icon', 'thumbnail', 'window', 'lineItem',
    ].includes(key))
    && (0, assertions_1.assertTrue)(['string'].includes(typeof input.url), new errors_1.InvalidRequestError('url must be a string'))
    && (0, assertions_1.assertTrue)(['string'].includes(typeof input.title), new errors_1.InvalidRequestError('title must be a string'))
    && (0, assertions_1.assertTrue)(['string', 'undefined'].includes(typeof input.text), new errors_1.InvalidRequestError('text must be a string'))
    && (0, assertions_1.assertTrue)(typeof input.window === 'undefined' || validateLtiLinkWindow(input.window), new errors_1.InvalidRequestError('window must be an object'))
    && (0, assertions_1.assertTrue)(typeof input.icon === 'undefined' || validateLtiImage(input.icon), new errors_1.InvalidRequestError('icon must be an object'))
    && (0, assertions_1.assertTrue)(typeof input.thumbnail === 'undefined' || validateLtiImage(input.thumbnail), new errors_1.InvalidRequestError('thumbnail must be an object'))
    && (0, assertions_1.assertTrue)(typeof input.lineItem === 'undefined' || validateLtiLineItem(input.lineItem), new errors_1.InvalidRequestError('lineItem must be an object'));
exports.validateLtiResourceLink = validateLtiResourceLink;
const isLtiResourceLink = (input) => {
    try {
        return (0, exports.validateLtiResourceLink)(input);
    }
    catch (e) {
        // re-throws if error is unexpected
        (0, assertions_1.assertErrorInstanceOf)(e, errors_1.InvalidRequestError);
        return false;
    }
};
exports.isLtiResourceLink = isLtiResourceLink;
const validateLtiResource = (input) => (0, guards_1.isPlainObject)(input)
    && Object.keys(input).every((key) => ['item', 'editUrl'].includes(key))
    && (0, exports.validateLtiResourceLink)(input.item)
    && (0, assertions_1.assertTrue)(['string', 'undefined'].includes(typeof input.editUrl), new errors_1.InvalidRequestError('editUrl must be a string'));
exports.validateLtiResource = validateLtiResource;
const isLtiResource = (input) => {
    try {
        return (0, exports.validateLtiResource)(input);
    }
    catch (e) {
        // re-throws if error is unexpected
        (0, assertions_1.assertErrorInstanceOf)(e, errors_1.InvalidRequestError);
        return false;
    }
};
exports.isLtiResource = isLtiResource;
