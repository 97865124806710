import * as UI from '@openstax/ui-components';
import React from 'react';

export const Redirect = (props: {url: undefined | string}) => {
       
  React.useEffect(() => {
    if (props.url) {
      window.location.assign(props.url);
    }
  }, [props.url]);

  return <UI.Loader />;
};
