"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.accessRolePriority = exports.AccessRole = exports.requiredStudentContractNames = exports.requiredInstructorContractNames = exports.SessionProblemTypes = void 0;
var SessionProblemTypes;
(function (SessionProblemTypes) {
    SessionProblemTypes["SIGNATURE_REQUIRED"] = "SIGNATURE_REQUIRED";
    SessionProblemTypes["UNLINKED_INSTRUCTOR_ACCOUNT"] = "UNLINKED_INSTRUCTOR_ACCOUNT";
    SessionProblemTypes["UNVERIFIED_INSTRUCTOR_ACCOUNT"] = "UNVERIFIED_INSTRUCTOR_ACCOUNT";
})(SessionProblemTypes = exports.SessionProblemTypes || (exports.SessionProblemTypes = {}));
exports.requiredInstructorContractNames = [];
exports.requiredStudentContractNames = ['assignable_student_tos'];
var AccessRole;
(function (AccessRole) {
    AccessRole["INSTRUCTOR"] = "INSTRUCTOR";
    AccessRole["STUDENT"] = "STUDENT";
})(AccessRole = exports.AccessRole || (exports.AccessRole = {}));
exports.accessRolePriority = [
    AccessRole.STUDENT,
    AccessRole.INSTRUCTOR
];
