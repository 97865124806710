import {
  CreateDynamicPlatformPayload,
  CreatePlatformDeploymentPayload,
  CreatePlatformPayload
} from '@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/platforms';
import { fetchIdle, fetchLoading, FetchState } from "@openstax/ts-utils/fetch";
import { PlatformInfo } from './types';

export type ScreenState = {
  platformInfo: FetchState<PlatformInfo, string>;
  dynamicPlatformPayload: CreateDynamicPlatformPayload['platform'] | null;
  platformPayload: CreatePlatformPayload['platform'] | null;
  platformDeploymentPayload: CreatePlatformDeploymentPayload['deployment'] | null;
  contactPayload: CreatePlatformPayload['contact'] & {
    organizationName: string;
  };
  activeScreen: 'info' | 'create' | 'createDeployment' | 'success';
  activeTabId: string;
  apiState: FetchState<null, string>;
  blackboardFormFields: {
    deploymentId: string;
  };
  canvasFormFields: {
    clientId: string;
    issuer: string;
  };
  otherFormFields: {
    clientId: string;
    keysetUrl: string;
    accessTokenUrl: string;
    authEndpoint: string;
    issuer: string;
  };
};

export type FieldActions = {
  type: 'setBlackboardFormField';
  field: keyof ScreenState['blackboardFormFields'];
  payload: string;
} | {
  type: 'setCanvasFormField';
  field: keyof ScreenState['canvasFormFields'];
  payload: string;
} | {
  type: 'setOtherFormField';
  field: keyof ScreenState['otherFormFields'];
  payload: string;
} | {
  type: 'setContactField';
  field: keyof ScreenState['contactPayload'];
  payload: string;
};

export type ScreenStateActions = {
  type: 'setPlatformInfo';
  payload: ScreenState['platformInfo'];
} | {
  type: 'setPlatformPayload';
  payload: ScreenState['platformPayload'];
} | {
  type: 'setPlatformDeploymentPayload';
  payload: ScreenState['platformDeploymentPayload'];
} | {
  type: 'setContactOrganization';
  payload: Pick<ScreenState['contactPayload'], 'organizationId' | 'organizationName'>;
} | {
  type: 'setActiveScreen';
  payload: ScreenState['activeScreen'];
} | {
  type: 'setActiveTabId';
  payload: ScreenState['activeTabId'];
} | {
  type: 'setApiState';
  payload: ScreenState['apiState'];
} | FieldActions;

export const initialState: ScreenState = {
  platformInfo: fetchLoading(),
  dynamicPlatformPayload: null,
  platformPayload: null,
  platformDeploymentPayload: null,
  contactPayload: {
    adminEmail: '',
    adminName: '',
    organizationId: '',
    organizationName: ''
  },
  activeScreen: 'info',
  activeTabId: 'canvas',
  apiState: fetchIdle(),
  blackboardFormFields: {
    deploymentId: '',
  },
  canvasFormFields: {
    clientId: '',
    issuer: '',
  },
  otherFormFields: {
    clientId: '',
    keysetUrl: '',
    accessTokenUrl: '',
    authEndpoint: '',
    issuer: '',
  }
};

export const reducer = (state: ScreenState, action: ScreenStateActions): ScreenState => {
  switch (action.type) {
    case 'setPlatformInfo':
      return {
        ...state,
        platformInfo: action.payload,
      };
    case 'setPlatformPayload':
      return {
        ...state,
        platformPayload: action.payload,
      };
    case 'setPlatformDeploymentPayload':
      return {
        ...state,
        platformDeploymentPayload: action.payload,
      };
    case 'setActiveScreen':
      return {
       ...state,
        activeScreen: action.payload,
      };
    case 'setActiveTabId':
      return {
       ...state,
        activeTabId: action.payload,
      };
    case 'setApiState':
      return {
        ...state,
        apiState: action.payload,
      };
    case 'setBlackboardFormField':
      return {
        ...state,
        blackboardFormFields: {
          ...state.blackboardFormFields,
          [action.field]: action.payload,
        }};
    case 'setCanvasFormField':
      return {
        ...state,
        canvasFormFields: {
          ...state.canvasFormFields,
          [action.field]: action.payload,
        }};
    case 'setOtherFormField':
      return {
        ...state,
        otherFormFields: {
          ...state.otherFormFields,
          [action.field]: action.payload,
        }};
    case 'setContactField':
      return {
        ...state,
        contactPayload: {
          ...state.contactPayload,
          [action.field]: action.payload,
        },
      };
    case 'setContactOrganization':
      return {
        ...state,
        contactPayload: {
          ...state.contactPayload,
          organizationId: action.payload.organizationId,
          organizationName: action.payload.organizationName,
        },
      };
    default:
      return state;
  }
};
