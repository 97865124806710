// spell-checker: ignore imsglobal
export const closeLtiIframe = () => (
  window.opener || window.parent
).postMessage({subject: 'org.imsglobal.lti.close'}, '*');

export const isBlackboardIssuer = (value: string) => 'https://blackboard.com' === value;

export const isCloudCanvasIssuer = (value: string) => 'https://canvas.instructure.com' === value;

// https://stackoverflow.com/a/4964766
export const validateEmail = (email: string) => /^\S+@\S+\.\S+$/.test(email);
