import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import {
    fetchError, fetchIdle, fetchLoading, fetchSuccess, stateHasData,
    stateHasError
} from "@openstax/ts-utils/fetch";
import * as UI from '@openstax/ui-components';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useApiClient } from "../../../api";
import { createRoute, makeScreen } from "../../../core/services";
import { isBlackboardIssuer, isCloudCanvasIssuer } from './helpers';
import { PlatformInfo } from './types';
import { initialState, reducer, ScreenState, ScreenStateActions } from './reducer';
import { CheckboxField, CopyField, InputField, RadioField } from './fields';
import { AdminInfoFields, FormTag, SubmitAdminInfoButton, useAdminInfoIsValid } from "./AdminInfoForm";
import * as Sentry from '@sentry/react';

export const StateContext = React.createContext<{
  state: ScreenState;
  dispatch: React.Dispatch<ScreenStateActions>;
}>({
  state: initialState,
  dispatch: () => {}
});

interface PanelProps {
  tabId: 'blackboard' | 'canvas' | 'd2lBrightspace' | 'moodle' | 'other';
};

interface PlatformPanelProps<T extends keyof PlatformInfo> extends PanelProps {
  tabId: T extends 'dynamic' ? 'd2lBrightspace' | 'moodle' : T;
  fields: PlatformInfo[T];
};

export const useInfoUrl = () => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const { state, dispatch } = React.useContext(StateContext);

  React.useEffect(() => {

    if (stateHasData(state.platformInfo)) {
      return;
    }
    apiClient.apiV0PlatformInfo({})
      .then(response => response.acceptStatus(200).load())
      .then(response => dispatch({ type: 'setPlatformInfo', payload: fetchSuccess(response) }))
      .catch(setAppError)
    ;
  }, [apiClient, dispatch, state.platformInfo, setAppError]);

  return state.platformInfo;
};

export const InfoLink = styled.a`
  line-height: 2.4rem;
  text-decoration: none;
  color:  ${UI.colors.palette.blue};
  margin: 2.4rem 0 3.2rem;
  [data-icon] {
    margin-right: 1.1rem;
  }
`;

const Heading = styled.h1`
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 1rem 0 2rem;
`;

const footerHeight = 5.2;
const maxWidthCss = css`
  max-width: 74rem;
  margin: 0 auto;
`;

export const ScreenWrapper = styled.div`
  min-height: 100%;
  height: auto;
  ${(props: { flex?: boolean }) => props.flex && css`
    display: flex;
    flex-direction: column;
  `}
`;

export const BodyWrapper = styled.div`
  padding: 1.6rem 1.6rem 3.2rem;
  font-size: 1.6rem;
  color: #424242;
  ${maxWidthCss}

  h3 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-top: 0;
  }

  p {
    line-height: 2.4rem;
  }
`;

const StyledLoader = styled(UI.Loader)`
  height: 100vh;
`;

const FieldHelpText = styled.div `
  font-size: 1.4rem;
`;

export const WarningText = styled.div`
  background: #fff5e0;
  color: #976502;
  border: 1px solid #fdbd3e;
  padding: 1.6rem;
  margin: 0;
`;

const Header = styled((props) => {
  const elementRef = React.useRef<HTMLDivElement | null>(null);
  const observerRef = React.useRef<IntersectionObserver | null>(null);
  const [isScrolled, setIsScrolled] = React.useState(false);

  React.useEffect(() => {
    if (!elementRef.current) { return; }
    observerRef.current = new IntersectionObserver((entries) => {
      setIsScrolled(!entries[0].isIntersecting);
    }, {
      threshold: [1]
    });

  }, []);

  React.useEffect(() => {
    if (!observerRef.current || !elementRef.current) { return; }
    observerRef.current.observe(elementRef.current);

    return () => {
      observerRef?.current?.disconnect();
    };
  }, [elementRef]);

  return <header
    ref={elementRef}
    className={`${props.className} ${isScrolled ? 'scrolled' : ''}`}
  >
    {props.children}
  </header>;
})`
  ${BodyWrapper} {
    padding-bottom: 1.6rem;
    z-index: 3;
  }
  width: 100%;
  top: -1rem;
  background: #fff;
  z-index: 1;
  position: sticky;
   -webkit-backface-visibility: hidden;
  &.scrolled {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
`;

export const Footer = styled((props: { className?: string; children: ReactNode }) => (
  <div className={props.className}>
    <div className='footer-inner'>
      {props.children}
    </div>
  </div>
))`
  position: sticky;
  top: 100vh;
  background: #fafafa;
  border-top: 1px solid #d5d5d5;

  .footer-inner {
    ${maxWidthCss}
    height: ${footerHeight}rem;
    padding: 0 1.6rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  button {
    height: 3rem;
    font-size: 1.4rem;
  }
`;

const Panel = styled(({ tabId, children, ...props }: React.PropsWithChildren<PanelProps>) => {
  const { state } = React.useContext(StateContext);

  return <div
    {...props}
    id={`${tabId}-panel`}
    hidden={tabId !== state.activeTabId}
    aria-labelledby={`${tabId}-tab`}
    tabIndex={0}
  >
    {children}
  </div>;
})`
  flex: 1;
`;

const Panels = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  h3:not(:first-child) {
    margin-top: 3.6rem;
  }

  ${InfoLink} {
    display: inline-block;
    margin: 1rem 0;
  }
  ${FieldHelpText} > ${InfoLink} {
    margin: 0;
  }
`;

const Tabs = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;
`;

const Tab = styled(({ className, children, tabId }: {
  className?: string;
  children: React.ReactNode;
  tabId: PanelProps['tabId'];
  activeTabId: ScreenState['activeTabId'];
}) => {
  const { state, dispatch } = React.useContext(StateContext);

  return <button
    id={`${tabId}-tab`}
    className={className}
    onClick={() => dispatch({ type: 'setActiveTabId', payload: tabId })}
    role='tab'
    aria-selected={state.activeTabId === tabId}
    aria-controls={`${tabId}-panel`}
    disabled={state.activeScreen !== 'info'}
    tabIndex={0} // Should probably set to -1 for non-active and use keyboard arrows instead
  >
    {children}
  </button>;
})`
  flex: 1;
  height: 3.3rem;
  background: #f6f7f8;
  color: ${UI.colors.palette.neutralDarker};
  border: 1px solid #e5e5e5;
  cursor: pointer;
  font-size: 1.6rem;
  letter-spacing: -4%;

  &:disabled {
    color: #c1c1c1;
  }

  &:not(:disabled):hover {
    background: #fafbfc;
  }
  & + & {
    border-left: 0;
  }

  ${({ tabId, activeTabId }: { tabId: string; activeTabId: string }) => tabId === activeTabId && css`
    &, &:hover, &:disabled, &:not(:disabled):hover {
      background: #e5e5e5;
      color: #424242;
    }
  `}
`;

export const CustomerSupportLink = (props: React.PropsWithChildren<{page?: string}>) => <a
  href={'https://help.openstax.org/s' + (props.page ? `/${props.page}` : '')}
  target="_blank"
  rel="noreferrer"
>{props.children}</a>;

const BlackboardPanel = ({ fields, ...props }: PlatformPanelProps<'blackboard'>) => {
  const { state, dispatch } = React.useContext(StateContext);
  const { deploymentId } = state.blackboardFormFields;

  const isValid = React.useMemo(() => !!deploymentId, [deploymentId]);

  if (!stateHasData(state.platformInfo)) {
    throw new Error('Platform info data not found');
  }

  const clientId = state.platformInfo.data.blackboard.clientId;

  const dispatcher = (field: keyof ScreenState['blackboardFormFields']) => (payload: string) => dispatch({
    type: 'setBlackboardFormField',
    field,
    payload
  });

  const saveAndContinue = () => {
    dispatch({
      type: 'setPlatformDeploymentPayload',
      payload: {
        clientId,
        deploymentId,
        iss: 'https://blackboard.com',
      }
    });
    dispatch({ type: 'setActiveScreen', payload: 'createDeployment' });
  };

  return <Panel {...props}>
    <BodyWrapper>
      <InfoLink target="_blank" href="https://help.openstax.org/s/article/Blackboard-LMS-integration">
        <FontAwesomeIcon icon={faCircleInfo} /> How do I integrate with Blackboard Learn?
      </InfoLink>
      <InfoLink
        target="_blank"
        href="https://help.openstax.org/s/article/How-do-I-integrate-OpenStax-Assignable-beta-with-Blackboard-Ultra"
      >
        <FontAwesomeIcon icon={faCircleInfo} /> How do I integrate with Blackboard Ultra?
      </InfoLink>
      <h3>1. Copy the information below and paste into Blackboard where prompted.</h3>
      <CopyField label='Client ID' value={fields.clientId} />
      <h3>2. Copy the following from Blackboard into the fields below.</h3>
      <InputField label='Deployment ID' value={deploymentId} onChange={dispatcher('deploymentId')} />
    </BodyWrapper>
    <Footer>
      <UI.Button disabled={!isValid} onClick={saveAndContinue}>
        Save & Continue
      </UI.Button>
    </Footer>
  </Panel>;
};

const CanvasPanel = ({ fields, ...props }: PlatformPanelProps<'canvas'>) => {
  const { state, dispatch } = React.useContext(StateContext);
  const { clientId } = state.canvasFormFields;

  // This prevents leading/trailing whitespace from being typed in the issuer field
  const issuer = React.useMemo(() => state.canvasFormFields.issuer.trim(), [state.canvasFormFields.issuer]);

  // This is separate because we must allow trailing / to be typed in the issuer field at least temporarily
  const normalizedIssuer = React.useMemo(() => issuer.replace(/\/+$/, ''), [issuer]);

  const [deploymentType, setDeploymentType] = React.useState<null | string>(null);

  const issuerIsBlackboard = isBlackboardIssuer(normalizedIssuer);
  const issuerIsCloudCanvas = isCloudCanvasIssuer(normalizedIssuer);
  const isValid = React.useMemo(
    () => !!clientId && (deploymentType === 'cloud' || (
      !!normalizedIssuer && !issuerIsBlackboard && !issuerIsCloudCanvas
    )), [clientId, deploymentType, normalizedIssuer, issuerIsBlackboard, issuerIsCloudCanvas]
  );

  if (!stateHasData(state.platformInfo)) {
    throw new Error('Platform info data not found');
  }

  const cloudIssuer = state.platformInfo.data.canvas.cloudIssuer;
  const cloudUrlBase = state.platformInfo.data.canvas.cloudUrlBase;

  const dispatcher = (field: keyof ScreenState['canvasFormFields']) => (payload: string) => dispatch({
    type: 'setCanvasFormField',
    field,
    payload
  });

  const saveAndContinue = () => {
    // normalizedIssuer here removes trailing / from the issuer field on save
    const url = deploymentType === 'cloud' ? cloudIssuer : normalizedIssuer;

    dispatch({
      type: 'setPlatformPayload',
      payload: {
        url,
        name: 'Canvas',
        clientId,
        authenticationEndpoint: `${cloudUrlBase}/api/lti/authorize_redirect`,
        accesstokenEndpoint: `${cloudUrlBase}/login/oauth2/token`,
        authConfigKey: `${cloudUrlBase}/api/lti/security/jwks`,
        authConfigMethod: 'JWK_SET'
      }
    });
    dispatch({ type: 'setActiveScreen', payload: 'create' });
  };

  return <Panel {...props}>
    <BodyWrapper>
      <h3>1. What type of Canvas deployment do you have?</h3>
      <InfoLink target="_blank" href="https://help.openstax.org/s/article/Canvas-LMS-integration">
        <FontAwesomeIcon icon={faCircleInfo} /> How do I integrate with Canvas?
      </InfoLink>
      <RadioField
        value={deploymentType}
        onChangeValue={setDeploymentType}
        options={[
          {label: 'Cloud-hosted Canvas', value: 'cloud'},
          {label: 'Self-hosted Canvas', value: 'self'},
        ]}
      />
      {deploymentType && <>
        <h3>2. Copy the information below and paste into Canvas where prompted.</h3>
        <CopyField
          label='JSON URL'
          helperText='Select Method: "Enter URL" and use the JSON URL below:'
          value={fields.jsonUrl}
        />
        <h3>3. Copy the following from Canvas into the fields below.</h3>
        {deploymentType === 'self' && <>
          <InputField
            label='Issuer'
            value={issuer} // issuer here allows trailing / to be typed in the field
            onChange={dispatcher('issuer')}
            invalid={issuerIsBlackboard || issuerIsCloudCanvas}
            invalidText={<>You may only register your Self-hosted Canvas after you change its "issuer" value.
              For further assistance, please visit our <CustomerSupportLink page="contactsupport">
              support center</CustomerSupportLink>.</>}
          />
          <FieldHelpText>
            Help me find my <InfoLink
              target="_blank"
              // eslint-disable-next-line max-len
              href="https://help.openstax.org/s/article/What-is-the-issuer-field-for-my-self-hosted-Canvas-integration-with-OpenStax-Assignable"
            >
              issuer url.
            </InfoLink>
          </FieldHelpText>
        </>}
        <InputField label='Client ID' value={clientId} onChange={dispatcher('clientId')} />
      </>}
    </BodyWrapper>
    <Footer>
      <UI.Button disabled={!isValid} onClick={saveAndContinue}>
        Save & Continue
      </UI.Button>
    </Footer>
  </Panel>;
};

const DynamicPanel = ({ fields, helpArticle, lmsName, ...props }: PlatformPanelProps<'dynamic'> & {
  helpArticle: string;
  lmsName: string;
}) => <Panel {...props}>
  <BodyWrapper>
    <h3>1. Register using LTI Advantage Dynamic Registration</h3>
    <InfoLink target="_blank" href={`https://help.openstax.org/s/article/${helpArticle}`}>
      <FontAwesomeIcon icon={faCircleInfo} /> How do I integrate with {lmsName}?
    </InfoLink>
    <CopyField label='LTI Advantage Tool URL' value={fields.ltiAdvantageToolUrl} />
  </BodyWrapper>
</Panel>;

const MoodlePanel = ({ fields, ...props }: PlatformPanelProps<'dynamic'>) => {
  const dynamicProps = {
    helpArticle: 'Moodle-LMS-integration',
    lmsName: 'Moodle',
    ...props,
  };

  return <DynamicPanel fields={fields} {...dynamicProps} />;
};

const D2LBrightspacePanel = ({ fields, ...props }: PlatformPanelProps<'dynamic'>) => {
  const dynamicProps = {
    helpArticle: 'D2L-Brightspace-LMS-integration',
    lmsName: 'Brightspace by D2L',
    ...props,
  };

  return <DynamicPanel fields={fields} {...dynamicProps} />;
};

const OtherPanel = ({ fields, ...props }: PlatformPanelProps<'other'>) => {
  const { state, dispatch } = React.useContext(StateContext);
  const { clientId, keysetUrl, accessTokenUrl, authEndpoint } = state.otherFormFields;
  // This prevents leading/trailing whitespace from being typed in the issuer field
  const issuer = React.useMemo(() => state.otherFormFields.issuer.trim(), [state.otherFormFields.issuer]);

  const issuerIsBlackboard = React.useMemo(() => isBlackboardIssuer(issuer), [issuer]);
  const issuerIsCloudCanvas = React.useMemo(() => isCloudCanvasIssuer(issuer), [issuer]);
  const isValid = React.useMemo(() =>
    !!clientId && !!keysetUrl && !!accessTokenUrl && !!authEndpoint &&
    !!issuer && !issuerIsBlackboard && !issuerIsCloudCanvas
  , [clientId, keysetUrl, accessTokenUrl, authEndpoint, issuer, issuerIsBlackboard, issuerIsCloudCanvas]);

  const saveAndContinue = () => {
    dispatch({
      type: 'setPlatformPayload',
      payload: {
        url: issuer,
        name: `${state.contactPayload.organizationName} - Other`,
        clientId,
        authenticationEndpoint: authEndpoint,
        accesstokenEndpoint: accessTokenUrl,
        authConfigKey: keysetUrl,
        authConfigMethod: 'JWK_SET'
      }
    });

    dispatch({ type: 'setActiveScreen', payload: 'create'});
  };

  const dispatcher = (field: keyof ScreenState['otherFormFields']) => (payload: string) => dispatch({
    type: 'setOtherFormField',
    field,
    payload
  });

  return <Panel {...props}>
    <BodyWrapper>
      <WarningText>
        OpenStax Assignable can be manually registered with any Learning Management System (LMS)
        that supports LTI tools. However, OpenStax can only provide support for Canvas, Moodle,
        Blackboard, and D2L/Brightspace LMSs.
      </WarningText>
      <h3>1. Copy the information below and paste into your LMS where prompted.</h3>
      <InfoLink target="_blank" href="https://help.openstax.org/s/article/What-is-OpenStax-Assignable#manuallyregister">
        <FontAwesomeIcon icon={faCircleInfo} /> How do I integrate with my LMS?
      </InfoLink>
      <CopyField label='Name' value={fields.name} />
      <CopyField label='Description' value={fields.description} />
      <CopyField label='Domain' value={fields.domain} />
      <CopyField label='Launch URL' value={fields.redirectUrls.join(', ')} />
      <CopyField label='OpenID Connect Login URL' value={fields.openIdLoginUrl} />
      <CopyField label='Keyset URL' value={fields.keysetUrl} />

      <h3>2. If available, make sure the following boxes are checked in your LMS.</h3>
      <CheckboxField disabled checked indented size={1.6}>Assignment and Grade Services</CheckboxField>
      <CheckboxField disabled checked indented size={1.6}>Deep Linking</CheckboxField>
      <CheckboxField disabled checked indented size={1.6}>Send Institution Role</CheckboxField>

      <h3>3. Copy the following from your LMS into the fields below.</h3>
      <InputField label='Client ID' value={clientId} onChange={dispatcher('clientId') } />
      <InputField label='LMS keyset URL' value={keysetUrl} onChange={dispatcher('keysetUrl')} />
      <InputField
        label='LMS OAuth2 access token URL'
        value={accessTokenUrl}
        onChange={dispatcher('accessTokenUrl')}
      />
      <InputField
        label='OpenID connect authentication endpoint'
        value={authEndpoint}
        onChange={dispatcher('authEndpoint')}
      />
      <InputField
        label='Issuer'
        value={issuer}
        onChange={dispatcher('issuer')}
        invalid={issuerIsBlackboard || issuerIsCloudCanvas}
        invalidText={<>To register with Blackboard or Canvas, please use the appropriate tab. For further assistance,
please visit our <CustomerSupportLink page="contactsupport">support center</CustomerSupportLink>.</>}
      />
    </BodyWrapper>
    <Footer>
      <UI.Button disabled={!isValid} onClick={saveAndContinue}>
        Save & Continue
      </UI.Button>
    </Footer>
  </Panel>;
};

const PageHeader = () => {
  const activeTabId = React.useContext(StateContext).state.activeTabId;

  return <Header>
    <BodyWrapper>
      <Heading>Integrate with your learning management system (LMS)</Heading>
      <Tabs role="tablist" aria-label="Platforms">
        <Tab tabId='blackboard' activeTabId={activeTabId}>Blackboard</Tab>
        <Tab tabId='canvas' activeTabId={activeTabId}>Canvas</Tab>
        <Tab tabId='moodle' activeTabId={activeTabId}>Moodle</Tab>
        <Tab tabId='d2lBrightspace' activeTabId={activeTabId}>D2L Brightspace</Tab>
        <Tab tabId='other' activeTabId={activeTabId}>Other</Tab>
      </Tabs>
    </BodyWrapper>
  </Header>;
};

const PlatformTabPanels = ({ platformFields }: { platformFields: PlatformInfo }) => {
  return <>
    <PageHeader />
    <Panels>
      <BlackboardPanel tabId='blackboard' fields={platformFields.blackboard} />
      <CanvasPanel tabId='canvas' fields={platformFields.canvas} />
      <MoodlePanel tabId='moodle' fields={platformFields.dynamic} />
      <D2LBrightspacePanel tabId='d2lBrightspace' fields={platformFields.dynamic} />
      <OtherPanel tabId='other' fields={platformFields.other} />
    </Panels>
 </>;
};

export const RegisterScreen = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const ActiveScreen = {
    'info': InfoScreen,
    'create': CreateScreen,
    'createDeployment': CreateDeploymentScreen,
    'success': SuccessScreen,
  }[state.activeScreen];

  return <StateContext.Provider value={{ state, dispatch }}>
    {stateHasError(state.apiState) ? <UI.Modal
      show
      variant='error'
      heading='Error'
      onModalClose={() => dispatch({ type: 'setApiState', payload: fetchIdle() })}
    >
      <UI.ModalBody>{state.apiState.error}</UI.ModalBody>
    </UI.Modal>
    : null}
    <ActiveScreen />
  </StateContext.Provider>;
};

export const InfoScreen = () => {
  const infoState = useInfoUrl();
  const platformFields = stateHasData(infoState) ? infoState.data : undefined;

  return <ScreenWrapper flex>
    <UI.NavBar logo />
    {platformFields ? <PlatformTabPanels platformFields={platformFields} /> : <StyledLoader />}
  </ScreenWrapper>;
};

const CreateScreenWrapper = (props: { dispatch: React.Dispatch<ScreenStateActions>; onSubmit: () => void }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <ScreenWrapper flex>
    <UI.NavBar logo />
    <PageHeader />
    <FormTag onSubmit={props.onSubmit}>
      <BodyWrapper>
        <AdminInfoFields />
      </BodyWrapper>
      <Footer>
        <UI.Button variant='light' onClick={() => props.dispatch({ type: 'setActiveScreen', payload: 'info' })}>
          Back
        </UI.Button>
        <SubmitAdminInfoButton />
      </Footer>
    </FormTag>
  </ScreenWrapper>;
};

export const CreateScreen = () => {
  const { adminName, adminEmail, organizationId } = React.useContext(StateContext).state.contactPayload;
  const apiClient = useApiClient();
  const { state, dispatch } = React.useContext(StateContext);
  const isValid = useAdminInfoIsValid();

  const onSubmit = () => {
    if (!organizationId || !isValid || !state.platformPayload) {
      return;
    }

    dispatch({ type: 'setApiState', payload: fetchLoading() });

    apiClient.apiV0CreatePlatform({
      payload: {
        platform: state.platformPayload,
        contact: {
          adminEmail,
          adminName,
          organizationId,
      }}
    })
    .then(response => response.acceptStatus(201, 409))
    .then((response) => {
      if (response.status === 409) {
        dispatch({
          type: 'setApiState',
          payload: fetchError(`There is an existing registration with that Client ID and Issuer.
Please check the values and try again.`)
        });
      } else {
        dispatch({
          type: 'setApiState',
          payload: fetchSuccess(null)
        });

        dispatch({
          type: 'setActiveScreen',
          payload: 'success'
        });
      }
    })
    .catch((e) => {
      Sentry.captureException(e);
      dispatch({
        type: 'setApiState',
        payload: fetchError('An error occurred, please try again or contact Customer Support.')
      });
    });
  };

  return <CreateScreenWrapper dispatch={dispatch} onSubmit={onSubmit} />;
};

export const CreateDeploymentScreen = () => {
  const { adminName, adminEmail, organizationId } = React.useContext(StateContext).state.contactPayload;
  const apiClient = useApiClient();
  const { state, dispatch } = React.useContext(StateContext);
  const isValid = useAdminInfoIsValid();

  const onSubmit = () => {
    if (!organizationId || !isValid || !state.platformDeploymentPayload) {
      return;
    }

    dispatch({ type: 'setApiState', payload: fetchLoading() });

    apiClient.apiV0CreatePlatformDeployment({
      payload: {
        contact: {
          adminEmail,
          adminName,
          organizationId,
        },
        deployment: state.platformDeploymentPayload,
      }
    })
    .then(response => response.acceptStatus(201, 409))
    .then((response) => {
      if (response.status === 409) {
        dispatch({
          type: 'setApiState',
          payload: fetchError('This Blackboard deployment has already been registered.')
        });
      } else {
        dispatch({
          type: 'setApiState',
          payload: fetchSuccess(null)
        });

        dispatch({
          type: 'setActiveScreen',
          payload: 'success'
        });
      }
    })
    .catch((e) => {
      Sentry.captureException(e);
      dispatch({
        type: 'setApiState',
        payload: fetchError('An error occurred, please try again or contact Customer Support.')
      });
    });
  };

  return <CreateScreenWrapper dispatch={dispatch} onSubmit={onSubmit} />;
};

const SuccessScreen = () => (
  <ScreenWrapper>
    <UI.NavBar logo />
    <BodyWrapper>
      <Heading>Success! The LTI registration process is complete.</Heading>
      <p>
        You have successfully added OpenStax Assignable to your LMS.
        If you are an LTI administrator, please let instructors know they can now use this tool.
        If you are an instructor, you can now create assignments using this tool directly in your LMS.
      </p>

      <p>Have other questions? Visit our <CustomerSupportLink>Support Center</CustomerSupportLink>.</p>
    </BodyWrapper>
  </ScreenWrapper>);

export const registerScreen = createRoute({
  name: 'Register Screen',
  path: '/register',
  handler: makeScreen(RegisterScreen)
});
